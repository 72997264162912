import { gql } from '@apollo/client/core';

export const AdminSiteQuery = gql`
  query AdminSite($id: Int!) {
    sites_by_pk(id: $id) {
      id
      name
      production_start_weekday
      client {
        name
        id
      }
      consumption_reference {
        co2
        consumption
        cost
        deployment_date
        id
        production
        site_id
      }
      areas {
        meters {
          meter_type_id
          data_source_id
          id
          area_id
          meter_type {
            energy_type
            name
            id
            step_seconds
          }
        }
        name
        parent_id
        production_reporting_frequency
        production_unit_id
        is_fabrication_order_reporting_enabled
        is_production_reporting_enabled
        id
        area_type
        fabrication_order_unit_id
      }
    }
  }
`;

export const AdminCreateSiteMutation = gql`
  mutation AdminCreateSite($object: sites_insert_input!) {
    insert_sites_one(object: $object) {
      id
    }
  }
`;

export const AdminUpdateSiteMutation = gql`
  mutation AdminUpdateSite($id: Int!, $object: sites_set_input!) {
    update_sites_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const AdminCreateReferenceMutation = gql`
  mutation AdminCreateReference($reference: consumption_reference_insert_input!) {
    insert_consumption_reference_one(object: $reference) {
      id
    }
  }
`;

export const AdminUpdateReferenceMutation = gql`
  mutation AdminUpdateReference($id: Int!, $reference: consumption_reference_set_input!) {
    update_consumption_reference_by_pk(pk_columns: { id: $id }, _set: $reference) {
      id
    }
  }
`;
