import {
  AdminCreateReferenceMutation,
  AdminCreateSiteMutation,
  AdminSiteQuery,
  AdminUpdateReferenceMutation,
  AdminUpdateSiteMutation,
} from '../../graphql/site';

export const useSiteApi = () => {
  const { apolloClient } = useApollo();
  const { showToast } = toastStore();
  const { t } = useI18n();
  const { setCurrentSite } = adminStore();

  const getSite = async (id: number) => {
    const { data } = await apolloClient.query({ query: AdminSiteQuery, variables: { id } });
    setCurrentSite(data.sites_by_pk);
  };

  const createSite = async (site: any) => {
    try {
      await apolloClient.mutate({ mutation: AdminCreateSiteMutation, variables: { object: site } });
      showToast({ type: 'success', title: t('global.success'), message: t('admin.site_created') });
    } catch (error) {
      showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
      throw error;
    }
  };

  const updateSite = async (id: number, site: any) => {
    try {
      await apolloClient.mutate({ mutation: AdminUpdateSiteMutation, variables: { id, object: site } });
      showToast({ type: 'success', title: t('global.success'), message: t('admin.site_updated') });
    } catch (error) {
      showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
      throw error;
    }
  };

  // ======================================================================== Reference ========================================================================
  const createReference = async (reference: any) => {
    try {
      await apolloClient.mutate({ mutation: AdminCreateReferenceMutation, variables: { reference } });
      showToast({ type: 'success', title: t('global.success'), message: t('admin.reference_created') });
    } catch (error) {
      showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
      throw error;
    }
  };

  const updateReference = async (id: number, reference: any) => {
    try {
      await apolloClient.mutate({ mutation: AdminUpdateReferenceMutation, variables: { id, reference } });
      showToast({ type: 'success', title: t('global.success'), message: t('admin.reference_updated') });
    } catch (error) {
      showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
      throw error;
    }
  };

  return {
    createSite,
    updateSite,
    getSite,
    createReference,
    updateReference,
  };
};
